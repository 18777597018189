import capitalize from "./capitalize";
import isNode from "./isNode";
import isReactNative from "./isReactNative";
import isWindows from "./isWindows";
import makeArrayOfLength from "./makeArrayOfLength";
import makeArrayOfStrings from "./makeArrayOfStrings";

export {
  capitalize,
  isNode,
  isReactNative,
  isWindows,
  makeArrayOfLength,
  makeArrayOfStrings,
};
